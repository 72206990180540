<template>
  <div class="view-home" v-if="proc === 1">
    <div class="container questions-wrap pt-5" v-if="!loading">
      <div class="question-title">
        <h3 class="text-primary">{{ $t('rules.title-item') }}</h3>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="question-content">
            <i class="el-icon-warning-outline" style="color: red"></i>
            {{ $t('rules.title-text') }} !
            <i class="el-icon-warning-outline" style="color: red"></i>
          </div>
          <div class="question-content">
            {{ $t('rules.not-allowed') }}
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12 d-flex flex-column align-items-center test-rule">
              <img height="170" src="/images/rule1.svg" alt="">
              <img class="pb-4 pt-4" width="70" src="/images/no.png" alt="">
              <div>- {{ $t('rules.rule-1') }}</div>
              <div>- {{ $t('rules.rule-4') }}</div>
            </div>
            <div class="col-lg-4 col-md-12 d-flex flex-column align-items-center test-rule">
              <img height="170" src="/images/rule2.svg" alt="">
              <img class="pb-4 pt-4" width="70" src="/images/no.png" alt="">
              <div>- {{ $t('rules.rule-2') }}</div>
              <div>- {{ $t('rules.rule-5') }}</div>
            </div>
            <div class="col-lg-4 col-md-12 d-flex flex-column align-items-center test-rule">
              <img height="170" src="/images/rule3.svg" alt="">
              <img class="pb-4 pt-4" width="70" src="/images/no.png" alt="">
              <div>- {{ $t('rules.rule-3') }}</div>
              <div>- {{ $t('rules.rule-6') }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 d-flex flex-row test-rule justify-content-center">
              <img height="284" src="/images/rule4.svg" alt="">
              <img class="mt-auto tick" width="70" src="/images/yes.png" alt="">
            </div>
            <div class="col-lg-6 col-md-6 test-rules-list">
              <div class="faq_answers" style="padding-bottom: 10px; padding-top: 20px">
                <i class="el-icon-success" style="color: green"></i>
                {{ $t('rules.allowed') }}
              </div>
              <div class="faq_answers" style="padding-bottom: 0;">
                <i class="el-icon-success" style="color: green"></i>
                {{ $t('rules.rule-7') }}
              </div>
              <div class="faq_answers" style="padding-bottom: 0;">
                <i class="el-icon-success" style="color: green"></i>
                {{ $t('rules.rule-8') }}
              </div>
              <div class="faq_answers" style="padding-bottom: 0;">
                <i class="el-icon-success" style="color: green"></i>
                {{ $t('rules.rule-9') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="mx-auto">
          <a @click="next" class="btn btn-primary">{{ $t('rules.next-button') }}</a>
          <!--        <router-link to="/identification" tag="a" class="btn btn-primary">Следующий шаг</router-link>-->
        </div>
      </div>
    </div>
    <Loader v-else/>
  </div>
</template>
<script>
import Loader from '../../components/Loader';
import Vue from "vue";

export default {
  name: "Index",
  data() {
    return {
      proc: 0,
      test_lang: 'ru',
      loading: true,
      isMobile: false,
      isMobileWidth: false,
    }
  },
  components: {
    Loader
  },
  methods: {
    next() {

      if (!(this.getWebCam && this.getWebContents)) {
        setTimeout( () => {
          this.$router.go(0)
        }, 2000)
        return Vue.toastr({
          message: 'Внимание!',
          description: this.$t('access_to_full_screen_recording_cam'),
          type: 'error',
        });
      }

      if (this.isMobile || this.isMobileWidth) {
        return Vue.toastr({
          message: this.$t('mobile_warning_title'),
          description: this.$t('mobile_warning'),
          type: 'error',
          timeout: 10000,
        });
      }

      let quiz = JSON.parse(localStorage.getItem('quiz'));
      let test_lang = localStorage.getItem('test_lang')
      if (test_lang) {
        this.test_lang = test_lang;
      }
      if (quiz.test_language) {
        this.$i18n.locale = this.test_lang;
      }
      if (quiz && quiz.proctoring) {
        if (quiz.proctoring == 1) {
          this.$router.push({name: 'identification'})
        } else {
          this.$router.push({name: 'about'})
        }
      } else {
        this.$router.push({name: 'about'})
      }
    },
    checkDevice() {
      this.isMobile = this.$device.ios || this.$device.android ||this.$device.ipad ||this.$device.iphone ||this.$device.ipod
      this.isMobileWidth = window.innerWidth <= 800;
      if (this.isMobileWidth || this.isMobile) {
        Vue.toastr({
          message: this.$t('mobile_warning_title'),
          description: this.$t('mobile_warning'),
          type: 'error',
          timeout: 10000,
        });
      }
    }
  },
  created() {
    this.checkDevice();
  },
  computed: {
    getWebCam() {
      return this.$store.state.proctoring.permissionWebCam;
    },
    getWebContents() {
      return this.$store.state.proctoring.permissionWebContents;
    }
  },
  mounted() {
    localStorage.setItem('userIIN', this.$route.query.iin)
    this.loading = true
    this.$http.get(API_ROOT + '/api/quiz/' + this.$route.params.token).then((res) => {
      if (res.body) {
        localStorage.removeItem('avatar');
        localStorage.removeItem('avatar-base64');
        localStorage.removeItem('quiz');
        localStorage.removeItem('timer');
        localStorage.removeItem('quiz-token');
        localStorage.removeItem('results');
        localStorage.removeItem('saved-answers');
        localStorage.removeItem('identification');
        localStorage.setItem('quiz-token', this.$route.params.token);
        localStorage.setItem('test_lang', res.body.test_language);
        if (res.body.data) {
          const quizData = res.body.data
          quizData.questions.forEach(question => {
            if (question.answer_type_id === 6) {
              const arr = question.answers[0][`text_${quizData.test_language}`].split('{word}')
              let newStr = ''
              for (let i = 0; i < arr.length; i++) {
                newStr += arr[i]
                if (i < arr.length - 1) {
                  newStr += `<input type="text" data-model="${i}" />`
                }
              }
              question.answers[0][`text_${quizData.test_language}`] = newStr
            }
            if (question.answer_type_id === 7) {
              const arr = question[`text_${quizData.test_language}`].split('{word}')
              const selects = []
              let text = ''
              for (let i = 0; i < question.answers.length; i++) {
                let select = `<select data-model="${i}" class="custom-answer-select">`
                question.answers[i].map(option => {
                  select += '<option>' + option[`text_${quizData.test_language}`] + '</option>'
                })
                select += '</select>'
                selects.push(select)
              }
              for (let i = 0; i < arr.length; i++) {
                text += arr[i]
                if (i < arr.length - 1) {
                  text += selects[i]
                }
              }
              question[`text_${quizData.test_language}`] = text
            }
          });
          localStorage.setItem('test_lang', res.body.test_language);
          localStorage.setItem('user_quiz_id', res.body.user_quiz_id)
          localStorage.setItem('user_id', res.body.user_id)
          localStorage.setItem('quiz', JSON.stringify(res.body.data));
          localStorage.setItem('timer', res.body.remaining_time);
          this.$i18n.locale = res.body.test_language;
        }
      }
      let quiz = JSON.parse(localStorage.getItem('quiz'));
      this.loading = false;
      if (quiz && quiz.proctoring === 0) {
        this.proc = 0;
        setTimeout(() => {
          this.$router.push({name: 'about'})
        }, 1000)

      }
      if (quiz && quiz.proctoring === 1) {
        this.proc = 1;
      }

    })
        .catch((e) => {
          this.loading = false;
          if (e.status === 404) {
            this.$router.push({name: 'not-found'})
          }
          // if(e.status >= 500) {
          //   alert('Данные не подгрузились. Возможно, высокая нагрузка на сайт или низкая скорость интернета. Перезагрузите страницу.')
          // }
        })
  }
}
</script>
<style>
.test-rule {
  padding: 70px 0;
}

.test-rule div {
  color: #e24c4b;
  font-size: 18px;
  line-height: 20px;
  /*text-align: center;*/
  width: 220px;
  margin-bottom: 10px;
}

.test-rules-list {
  color: #e24c4b;
  line-height: 20px;
  font-size: 18px;
}

.tick {
  margin-left: -55px;
  margin-bottom: -10px;
}

.test-rules-title {
  font-size: 22px;
  margin-bottom: 20px;
}

.test-rules-text {
  margin-bottom: 20px;
}
</style>
